.section.reviews {
  overflow: hidden;
  padding: 40px 0;

  .title {
    text-transform: uppercase;
    color: var(--black);
    margin-bottom: 30px;
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;

    .break1 {
      align-self: flex-start;
    }
    .break2 {
      align-self: flex-end;
    }
  }

  .reviews-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 30px;
    grid-template-areas:
      '. . . . .'
      '. . . . .';
    align-items: flex-end;

    .item {
      position: relative;
      margin: 0 auto;
      min-width: 240px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(9) {
        .video {
          object-position: center;
        }
      }
    }

    .position {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      text-transform: uppercase;
      text-align: right;
    }

    .video-box {
      position: relative;
      height: 280px;

      .overflow {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #00000096;
        z-index: 2;
      }

      .video {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        object-position: top;
      }

      .name {
        position: absolute;
        left: 21px;
        bottom: 18px;
        font-weight: 700;
        font-size: 14px;
        line-height: 129%;
        text-transform: uppercase;
        color: var(--white);
        z-index: 3;
      }

      .play-button {
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        background-color: #ccd0dcd9;
        border: none;
        border-radius: 50%;
      }
    }
  }
}

@include for-size(mobile-s) {
  .section.reviews {
    .title {
      font-size: 52px;
    }
  }
}

@include for-size(mobile-m) {
  .section.reviews {
    padding: 70px 0;

    .title {
      font-size: 71px;
    }

    .reviews-list {
      gap: 50px;

      .item {
        min-width: 240px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.reviews {
    position: relative;

    .title {
      position: absolute;
      top: 23%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 768px;
      font-weight: 700;
      font-size: 90px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--main-text);
      opacity: 0.2;
    }

    .reviews-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 50px;
      grid-template-areas:
        '. . .'
        '. . .'
        '. . .';
      align-items: flex-end;

      .item {
        min-width: 190px;

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(11),
        &:nth-child(14) {
          margin-bottom: 30px;
        }
      }
    }
  }
}

@include for-size(desktop-s) {
  .section.reviews {
    padding: 90px 0;

    .title {
      top: 21%;
      font-size: 115px;
      width: 992px;
    }

    .reviews-list {
      .item {
        min-width: 251px;
      }
    }
  }
}

@include for-size(desktop-m) {
  .section.reviews {
    padding: 90px 0;

    .title {
      top: 21%;
      font-size: 135px;
      width: 1200px;
    }

    .reviews-list {
      .item {
        min-width: 251px;
      }
    }
  }
}

@include for-size(desktop-l) {
  .section.reviews {
    padding: 0 0 165px 0;

    .title {
      top: 22%;
      font-size: 170px;
      width: 1600px;
    }

    .reviews-list {
      margin: 0 auto;
      width: 1190px;

      .item {
        min-width: 251px;

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(11),
        &:nth-child(14) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
