.section.money-safety {
  padding: 50px 0;

  .content-box {
    margin-bottom: 40px;

    .title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 31px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--black);
    }

    .subtitle {
      margin-bottom: 40px;
      font-weight: 400;
      font-size: 18px;
      line-height: 125%;
      text-transform: uppercase;
      color: var(--light-black);

      .break1 {
        margin-left: 70px;
      }

      .bg-text {
        font-weight: 400;
        align-self: flex-start;
      }
    }

    .text {
      line-height: 120%;
      .bold {
        font-weight: 500;
        color: var(--black);
      }
    }
  }
}

@include for-size(mobile-s) {
  .section.money-safety {
    .content-box {
      .title {
        font-size: 39px;
      }

      .subtitle {
        margin: 0 0 40px 60px;
        font-size: 20px;

        .break1 {
          margin-left: 90px;
        }
      }

      .text {
        font-size: 16px;
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.money-safety {
    .content-box {
      .title {
        margin-bottom: 30px;
        font-size: 58px;
      }

      .subtitle {
        margin: 0 0 50px 90px;
        font-size: 28px;

        .break1 {
          margin-left: 90px;
        }
      }

      .text {
        font-size: 16px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.money-safety {
    padding: 80px 0;

    .content-box {
      margin-bottom: 80px;

      .title {
        font-size: 68px;
      }

      .subtitle {
        margin: 0 0 50px 110px;
        font-size: 28px;

        .break1 {
          margin-left: 120px;
        }
      }

      .text {
        font-size: 18px;
      }
    }

    .img-box {
      width: 570px;
      margin-left: auto;
    }
  }
}

@include for-size(desktop-s) {
  .section.money-safety {
    .money-safety-container {
      display: flex;
      gap: 30px;
    }

    .content-box {
      flex-basis: calc((100% - 30px * 1) / 2);
      margin: 40px 0 0 0;

      .title {
        font-size: 48px;
      }

      .subtitle {
        margin: 0 0 50px 110px;
        font-size: 24px;

        .break1 {
          margin-left: 110px;
        }
      }

      .text {
        font-size: 20px;
      }
    }

    .img-box {
      flex-basis: calc((100% - 30px * 1) / 2);
      margin-left: 0;
    }
  }
}

@include for-size(desktop-m) {
  .section.money-safety {
    padding: 120px 0;

    .money-safety-container {
      gap: 30px;
    }

    .content-box {
      flex-basis: calc((100% - 30px * 1) / 2);
      margin: 60px 0 0 0;

      .title {
        font-size: 59px;
      }

      .subtitle {
        margin: 0 0 50px 110px;
        font-size: 32px;

        .break1 {
          margin-left: 140px;
        }
      }
    }

    .img-box {
      flex-basis: calc((100% - 30px * 1) / 2);
      margin-left: 0;
    }
  }
}

@include for-size(desktop-l) {
  .section.money-safety {
    padding: 130px 0;

    .money-safety-container {
      gap: 281px;
    }

    .content-box {
      flex-basis: unset;
      margin: 140px 0 0 0;

      .title {
        margin-bottom: 41px;
        font-size: 80px;
      }

      .subtitle {
        margin: 0 0 68px 138px;
        font-size: 32px;

        .break1 {
          margin-left: 136px;
        }
      }
    }

    .img-box {
      flex-basis: unset;
      width: 664px;
      margin-left: 0;
    }
  }
}
