@import '~node_modules/modern-normalize/modern-normalize.css';
@import '~node_modules/swiper/swiper-bundle.min.css';

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  font-optical-sizing: auto;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  font-variation-settings: 'slnt' 0;
  line-height: 1.3;
  color: var(--main-text);
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

a,
button,
input {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

address {
  font-style: normal;
}

button {
  cursor: pointer;
}

.container {
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;

  @include for-size(mobile-m) {
    max-width: 600px;
  }

  @include for-size(tablet) {
    max-width: 768px;
  }

  @include for-size(desktop-s) {
    max-width: 992px;
  }

  @include for-size(desktop-m) {
    max-width: 1200px;
  }

  @include for-size(desktop-l) {
    max-width: 1660px;
  }
}

.flex-column-text {
  display: flex;
  flex-direction: column;
}

.break {
  display: inline-block;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
  transition: all 250ms ease;
}
