.section-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  padding: 17px 16px;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  text-transform: uppercase;
  color: var(--black);
  background-color: transparent;
  border: 1px solid var(--black);
  border-radius: 60px;
  transition: var(--transition);

  .icon {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--green);
    transition: var(--transition);
  }

  &:hover,
  &:focus {
    background-color: var(--green);
    border-color: var(--green);

    .icon {
      background-color: var(--white);
    }
  }
}
