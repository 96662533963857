.header {
  padding: 12px 0;
}
.header-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.header-logo {
  display: block;
  font-weight: 700;
  font-size: 30px;
  line-height: 2.1;
  text-transform: uppercase;
  color: var(--black);
}
.header-cb-button {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--black);
  background-color: transparent;
  border: 0.5px solid var(--black);
  border-radius: 60px;
  transition: var(--transition);

  .icon {
    width: 13px;
    height: 10px;
    fill: var(--black);
    transition: var(--transition);
  }

  &:hover,
  &:focus {
    color: var(--white);
    background-color: var(--black);

    .icon {
      fill: var(--white);
    }
  }

  &.desktop {
    display: none;
  }
}
.header-nav {
  position: relative;
  top: -10px;
}
.link-nav {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.6;
  text-transform: uppercase;
  color: var(--main-text);
  transition: var(--transition);

  &:hover,
  &:focus {
    color: var(--black);
  }
}

@include for-size(mobile-m) {
  .list-nav {
    .item {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }

  .link-nav {
    font-size: 16px;
  }
}

@include for-size(tablet) {
  .header {
    padding: 20px 0;
  }

  .header-cb-button {
    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }

  .list-nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 20px;
    grid-auto-flow: row;
    grid-template-areas:
      'a d'
      'b .'
      'c .';

    .item {
      &:nth-child(1) {
        grid-area: a;
      }
      &:nth-child(2) {
        grid-area: b;
      }
      &:nth-child(3) {
        grid-area: c;
      }
      &:nth-child(4) {
        grid-area: d;
      }
    }
  }
}

@include for-size(desktop-s) {
  .list-nav {
    gap: 0px 80px;
  }
}

@include for-size(desktop-m) {
  .header-container {
    justify-content: flex-start;
  }

  .header-nav {
    margin-left: 116px;
  }

  .list-nav {
    gap: 0px 223px;
  }

  .header-cb-button {
    margin-left: auto;
  }
}

@include for-size(desktop-l) {
  .header {
    padding: 20px 0 4px 0;
  }

  .list-nav {
    gap: 0px 323px;
  }

  .header-cb-button {
    position: relative;
    bottom: 7px;
    left: -5px;
  }
}
