.section.partners {
  padding: 40px 0;
  overflow: hidden;

  .title {
    margin-bottom: 30px;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-transform: uppercase;
  }
}

.partners-block {
  display: none;

  .item {
    height: 52px;
    background-size: cover;
    background-repeat: repeat;
    transition: background-position 1000ms ease;
  }

  .item.first {
    margin-bottom: 20px;
    background-image: url(../images/partners/item-1.png);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/partners/item-1@2x.png);
    }
  }

  .item.second {
    background-image: url(../images/partners/item-2.png);

    @media screen and (min-device-pixel-ratio: 2),
      screen and (min-resolution: 192dpi),
      screen and (min-resolution: 2dppx) {
      background-image: url(../images/partners/item-2@2x.png);
    }
  }
}

.partners-infinite-carousel {
  pointer-events: none;

  .item {
    width: 1600px;
    height: 52px;
  }

  &.first {
    margin-bottom: 20px;
  }
}

@include for-size(tablet) {
  .section.partners {
    .title {
      margin-bottom: 43px;
    }
  }
}

@include for-size(desktop-s) {
  .partners-block {
    display: block;
  }

  .partners-infinite-carousel {
    display: none;
  }
}

@include for-size(desktop-l) {
  .section.partners {
    padding: 15px 0;
  }

  .partners-block {
    .item {
      height: 60px;
    }
  }
}
