@import './utils/variables';
@import './utils/mixins';
@import './base/common';
@import './layout/header';
@import './components/common/backdrop';
@import './components/common/main-title';
@import './components/common/circle-btn';
@import './components/common/bg-text';
@import './components/common/section-btn';
@import './components/common/count-number-list';
@import './components/hero';
@import './components/client-problems';
@import './components/benefits';
@import './components/how-it-works';
@import './components/partners';
@import './components/team';
@import './components/services';
@import './components/cases';
@import './components/about-ceo';
@import './components/safety';
@import './components/reviews';
@import './components/money-safety';
@import './components/cta';
@import './layout/footer';
@import './components/common/form';
@import './components/policy';
@import './pages/success';
