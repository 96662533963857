$breakpoints: (
  mobile-s: 390px,
  mobile-m: 600px,
  tablet: 768px,
  desktop-s: 992px,
  desktop-m: 1200px,
  desktop-l: 1660px,
);

@mixin for-size($range) {
  $mobile-s: map-get($breakpoints, mobile-s);
  $mobile-m: map-get($breakpoints, mobile-m);
  $tablet: map-get($breakpoints, tablet);
  $desktop-s: map-get($breakpoints, desktop-s);
  $desktop-m: map-get($breakpoints, desktop-m);
  $desktop-l: map-get($breakpoints, desktop-l);

  @if $range == mobile-only {
    @media screen and (max-width: #{$tablet - 1}) {
      @content;
    }
  } @else if $range == mobile-s {
    @media screen and (min-width: $mobile-s) {
      @content;
    }
  } @else if $range == mobile-m {
    @media screen and (min-width: $mobile-m) {
      @content;
    }
  } @else if $range == tablet {
    @media screen and (min-width: $tablet) {
      @content;
    }
  } @else if $range == desktop-s {
    @media screen and (min-width: $desktop-s) {
      @content;
    }
  } @else if $range == desktop-m {
    @media screen and (min-width: $desktop-m) {
      @content;
    }
  } @else if $range == desktop-l {
    @media screen and (min-width: $desktop-l) {
      @content;
    }
  }
}
