.section.cta {
  padding: 60px 0;

  .title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 28px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--black);

    .break1 {
      margin-left: 85px;
    }
  }
  .subtitle {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    text-transform: uppercase;
  }

  .circle-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cta-circle-btn {
    width: 166px;
    height: 166px;
    line-height: 120%;
  }
}

@include for-size(mobile-s) {
  .section.cta {
    .title {
      font-size: 33px;

      .break1 {
        margin-left: 120px;
      }

      .break2 {
        margin-left: 20px;
      }
    }

    .subtitle {
      margin: 0 0 40px 60px;
      font-size: 20px;
    }
  }
}

@include for-size(mobile-m) {
  .section.cta {
    .title {
      font-size: 53px;

      .break1 {
        margin-left: 180px;
      }
    }

    .subtitle {
      margin: 0 0 40px 80px;
      font-size: 28px;
    }

    .cta-circle-btn {
      width: 176px;
      height: 176px;
    }
  }
}

@include for-size(tablet) {
  .section.cta {
    padding: 80px 0;

    .title {
      margin-bottom: 50px;
      font-size: 68px;

      .break1 {
        margin-left: 250px;
      }
      .break2 {
        margin-left: 45px;
      }
    }
    .subtitle {
      font-size: 32px;
    }

    .cta-circle-btn {
      width: 256px;
      height: 256px;
    }
  }
}

@include for-size(desktop-s) {
  .section.cta {
    padding: 70px 0 200px 0;

    .cta-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin-bottom: 50px;
      font-size: 78px;

      .break1 {
        margin-left: 280px;
      }
    }

    .subtitle {
      margin: 0 0 0 300px;
      font-size: 32px;
    }

    .circle-btn-box {
      position: absolute;
      top: 70%;
      left: 20%;
    }

    .cta-circle-btn {
      width: 256px;
      height: 256px;
    }
  }
}

@include for-size(desktop-m) {
  .section.cta {
    padding: 70px 0 200px 0;

    .title {
      font-size: 98px;

      .break1 {
        margin-left: 380px;
      }
      .break2 {
        margin-left: 75px;
      }
    }

    .circle-btn-box {
      top: 75%;
    }
  }
}

@include for-size(desktop-l) {
  .section.cta {
    padding: 153px 0 200px 0;

    .title {
      margin: 0 18px 54px 0;
      font-size: 120px;

      .break1 {
        margin-left: 529px;
      }
      .break2 {
        margin-left: 167px;
      }
    }

    .subtitle {
      margin: 0 0 0 87px;
    }

    .circle-btn-box {
      top: 81%;
      left: 28%;
    }
  }
}
