.modal-policy {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 15px;
  margin: 0 15px;
  max-width: 550px;
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;

  .title {
    font-weight: 700;
    font-size: 20px;
    color: var(--black);
    text-align: center;
    margin-bottom: 15px;
  }
  .small-title {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 20px;
    color: var(--black);
  }
  .bold {
    color: var(--black);
  }

  p {
    margin-bottom: 15px;
  }
}

.content-policy {
  height: 390px;
  overflow-y: scroll;
}

@include for-size(tablet) {
  .modal-policy {
    max-width: 650px;
  }
}

@include for-size(desktop-s) {
  .modal-policy {
    padding: 90px 15px 50px 15px;
    max-width: 800px;
  }
}
