.count-number-list {
  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .number-box {
    margin-bottom: 12px;
    border-bottom: 1px solid var(--black);
    overflow: hidden;

    .number {
      position: relative;
      bottom: -25px;
      font-weight: 700;
      font-size: 100px;
      line-height: 1;
      text-transform: uppercase;
      color: #000;

      .plus {
        position: relative;
        bottom: 40px;
        font-size: 60px;
      }
    }
  }

  .item-text {
    text-align: right;
    font-size: 24px;
    line-height: 1.2;
    text-transform: uppercase;
  }
}

@include for-size(mobile-m) {
  .count-number-list {
    .item-text {
      margin-left: auto;
      max-width: 310px;
    }
  }
}

@include for-size(tablet) {
  .count-number-list {
    .item {
      .number {
        margin-left: 70px;
      }
    }
  }
}

@include for-size(desktop-s) {
  .count-number-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 50px;

    .item {
      flex-basis: calc((100% - 50px * 2) / 3);

      &:not(:last-child) {
        margin: 0;
      }
      .number {
        margin-left: 0;
      }
    }
  }
}

@include for-size(desktop-l) {
  .count-number-list {
    gap: 160px;

    .item {
      flex-basis: unset;
      width: 390px;
    }
  }
}
