.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  max-width: 575px;

  .label {
    position: relative;
    width: 100%;
    margin-bottom: 30px;

    .icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 17px;
      height: 17px;
      opacity: 0;
      transition: var(--transition);

      &.is-error {
        opacity: 1;
      }
    }
  }

  .label-title {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-weight: 700;
    font-size: 16px;
    line-height: 108%;
    text-transform: uppercase;
    color: var(--black);
  }

  .label-text {
    position: absolute;
    top: 50%;
    left: 100px;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: var(--main-text);

    &.is-hidden {
      opacity: 0;
    }
  }

  .form__input {
    padding: 5px 5px 5px 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: var(--main-text);
    border: none;
    border-bottom: 1px solid var(--black);
    width: 100%;
    outline-color: transparent;
  }

  .input-error {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: red;
    transition: var(--transition);
    opacity: 0;

    &.is-error {
      opacity: 1;
    }
  }
}

.submit-error {
  display: block;
  margin: 10px 0;
  text-align: center;
  color: red;
  transition: var(--transition);
  opacity: 0;

  &.is-error {
    opacity: 1;
  }
}

@include for-size(tablet) {
  .form {
    .label-title {
      font-size: 24px;
    }

    .label-text {
      left: 155px;
      font-size: 20px;

      &.is-hidden {
        opacity: 0;
      }
    }

    .form__input {
      padding: 5px 5px 5px 155px;
      font-size: 20px;
    }

    .form__submit-btn {
      margin-top: 20px;
    }
  }
}

@include for-size(desktop-s) {
  .form {
    padding: 0 10px;

    .label {
      margin-bottom: 35px;
    }
  }
}
