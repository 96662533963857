.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000ad;
  z-index: 60;
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
  transition-duration: 400ms;

  &.is-show {
    opacity: 1;
    pointer-events: all;
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    margin: 0 15px;
    max-width: 450px;
    width: 100%;
    background-color: var(--white);
    border-radius: 10px;
  }

  .modal__title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
    color: var(--black);

    .icon {
      margin-bottom: 10px;
      width: 26px;
      height: 26px;
    }
  }

  .modal__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: transparent;
    border: none;

    .icon {
      width: 32px;
      height: 32px;
      fill: var(--black);
    }
  }
}

@include for-size(tablet) {
  .backdrop {
    .modal {
      padding: 70px 0;
      max-width: 650px;
    }

    .modal__title {
      font-size: 40px;

      .icon {
      }
    }

    .modal__close-btn {
      top: 15px;
      right: 15px;
    }
  }
}

@include for-size(desktop-s) {
  .backdrop {
    .modal {
      padding: 90px 112px 100px 112px;
      max-width: 800px;
    }

    .modal__title {
      position: relative;
      margin-bottom: 68px;
      font-size: 60px;

      &.express-test {
        font-size: 40px;
        text-align: left;

        .break {
          white-space: nowrap;
        }

        .break1 {
          margin-left: 80px;
        }

        .break3 {
          margin-left: 210px;
        }
      }

      .icon {
        position: absolute;
        top: 4px;
        left: 0;
      }
    }

    .modal__close-btn {
      top: 25px;
      right: 25px;

      .icon {
        width: 43px;
        height: 43px;
        fill: var(--black);
      }
    }
  }
}
