.section.cases {
  padding: 90px 0;

  .title {
    margin-bottom: 70px;
    font-weight: 700;
    font-size: 70px;
    line-height: 71%;
    text-transform: uppercase;
    text-align: right;
    color: var(--black);
  }

  .circle-btn-box {
    position: relative;
    bottom: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cases-circle-btn {
      width: 166px;
      height: 166px;
    }
  }
}

.cases-list {
  transition: var(--transition);

  .item {
    padding: 40px 0;
    border-bottom: 1px solid var(--black);
    transition: var(--transition);

    &.is-hidden {
      opacity: 0;
    }
  }
}

.cases-company-box {
  margin-bottom: 50px;

  .cases-company-title {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 60px;
    line-height: 100%;
    text-transform: uppercase;
    word-wrap: break-word;
    color: var(--black);
  }

  .cases-ceo-box {
    display: flex;
    align-items: center;
    gap: 32px;
  }
  .cases-ceo-img-box {
    min-width: 84px;
    min-height: 84px;
  }
  .cases-business {
    font-size: 14px;
    line-height: 133%;
    text-transform: uppercase;
    color: var(--light-black);
  }
}

.cases-desc-box {
  overflow: hidden;
}

.tab-btn-list {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;

  .tab-btn {
    margin: 0;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-transform: uppercase;
    color: #9599a6;
    transition: var(--transition);
    background-color: transparent;
    border: none;

    &:hover,
    &:focus {
      color: var(--black);
    }

    &.is-active {
      color: var(--black);
    }
  }
}

.cases-desc-list {
  .desc-item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .text {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    line-height: 120%;

    .icon {
      margin-right: 15px;
      max-width: 17px;
      max-height: 17px;
    }

    .icon-exclamation {
      margin-right: 15px;
      max-width: 10px;
      max-height: 24px;
      font-weight: 500;
      font-size: 22px;
      line-height: 75%;
      color: var(--green);
    }

    .bold {
      font-weight: 500;
      color: var(--black);
    }
  }
}

@include for-size(mobile-m) {
  .section.cases {
    .title {
      font-size: 120px;
    }
  }

  .cases-company-box {
    .cases-company-title {
      margin-bottom: 25px;
      font-size: 68px;
    }

    .cases-business {
      font-size: 21px;
    }
  }

  .tab-btn-list {
    margin-bottom: 25px;
  }

  .cases-desc-list {
    .desc-item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .text {
      font-size: 18px;

      .icon {
        margin-right: 20px;
      }

      .icon-exclamation {
        margin-right: 20px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.cases {
    padding: 0 0 90px 0;

    .title {
      font-size: 140px;
    }

    .circle-btn-box {
      .cases-circle-btn {
        width: 252px;
        height: 252px;
      }
    }
  }

  .cases-company-box {
    .cases-business {
      font-size: 24px;
    }
  }

  .cases-desc-list {
    .text {
      font-size: 20px;
    }
  }
}

@include for-size(desktop-s) {
  .section.cases {
    .title {
      margin: 0 70px 70px 0;
    }
  }

  .cases-list {
    .item {
      padding: 50px 0;
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
  }

  .cases-company-box,
  .cases-desc-box {
    flex-basis: calc((100% - 20px * 1) / 2);
    margin-bottom: 0;
  }

  .cases-company-box {
    .cases-company-title {
      font-size: 44px;
    }
  }
}

@include for-size(desktop-m) {
  .section.cases {
    .title {
      font-size: 170px;
    }
  }

  .cases-company-box {
    .cases-company-title {
      font-size: 54px;
    }
  }
}

@include for-size(desktop-l) {
  .section.cases {
    .title {
      margin: 0 185px 95px 0;
    }
  }

  .cases-company-box {
    .cases-company-title {
      white-space: nowrap;
      margin-bottom: 32px;
      font-size: 80px;
    }

    .cases-ceo-box {
      margin-left: 23px;
    }
  }

  .cases-list {
    .item {
      justify-content: space-between;
      padding: 100px 0;
    }
  }

  .cases-company-box,
  .cases-desc-box {
    flex-basis: unset;
    width: 50%;
  }

  .cases-desc-box {
    width: 670px;
  }

  .tab-btn-list {
    gap: 53px;
    margin-bottom: 46px;
  }

  .tab-list {
    margin-left: 6px;
  }

  .cases-desc-list {
    .text {
      .icon,
      .icon-exclamation {
        margin-right: 30px;
      }
    }
  }
}
