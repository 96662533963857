.section.how-it-works {
  padding: 40px 0;

  .content-box {
    margin-bottom: 40px;

    .title {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 48px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--black);
    }

    .subtitle {
      font-size: 16px;

      .bold {
        font-weight: 500;
        color: var(--black);
      }
    }
  }

  .img-box {
    margin-bottom: 50px;
  }
}

.how-it-works-list {
  .item {
    position: relative;
    padding: 25px 0;

    &:nth-child(1),
    &:nth-child(2) {
      border-bottom: 1px dashed var(--main-text);
      &::after {
        content: '\2022';
        position: absolute;
        bottom: -17px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 28px;
        color: var(--black);
      }
    }

    &:nth-child(2) {
      .order-number {
        text-align: right;
      }

      .item-title {
        text-align: right;
      }

      .item-text {
        text-align: right;
      }
    }
  }

  .order-number {
    display: block;
    margin-bottom: 15px;
    font-weight: 100;
    font-size: 32px;
    line-height: 100%;
    text-transform: uppercase;

    .number {
      font-size: 28px;
      font-weight: 700;
      color: var(--green);
    }
  }

  .item-title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 26px;
    line-height: 100%;
    text-transform: uppercase;
    color: var(--black);
  }

  .item-text {
    font-size: 18px;
    line-height: 125%;
    text-transform: uppercase;
  }

  .design-line-desktop {
    display: none;
  }
}

@include for-size(mobile-s) {
  .section.how-it-works {
    .content-box {
      .title {
        font-size: 59px;
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.how-it-works {
    padding: 50px 0;

    .content-box {
      margin-bottom: 50px;

      .title {
        margin: 0 0 40px 50px;
      }

      .subtitle {
        margin-left: 200px;
        font-size: 18px;
      }
    }

    .img-box {
      margin-left: auto;
      max-width: 590px;
    }
  }
}

@include for-size(tablet) {
  .section.how-it-works {
    .content-box {
      .subtitle {
        margin-left: 250px;
        font-size: 18px;
      }
    }
  }

  .how-it-works-list {
    .item-title {
      font-size: 32px;
    }

    .item-text {
      font-size: 24px;
    }
  }
}

@include for-size(desktop-s) {
  .section.how-it-works {
    padding: 70px 0;

    .container1 {
      margin-bottom: 100px;
    }

    .flex-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 40px;
    }

    .content-box,
    .img-box {
      margin: 0;
      width: 50%;
    }

    .content-box {
      position: relative;
      .title {
        margin: 0 0 40px 0;
        font-size: 52px;

        .break1,
        .break2 {
          margin-left: 205px;
        }
      }

      .subtitle {
        margin: 0;
      }
    }

    .img-box {
      height: 420px;
    }
  }

  .how-it-works-list {
    position: relative;

    .container-desktop {
      display: flex;
      justify-content: space-between;
    }

    .width-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 270px;
    }

    .item {
      padding: 0;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        border-bottom: none;

        &::after {
          content: '\2022';
          position: absolute;
          top: 60%;
          bottom: unset;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 28px;
          color: var(--black);
          z-index: 5;
        }
      }

      &:nth-child(2) {
        .order-number {
          text-align: left;
        }

        .item-title {
          text-align: left;
        }

        .item-text {
          text-align: right;
        }
      }

      &:nth-child(3) {
        .item-text {
          margin-bottom: 20px;
        }
      }

      .item-text {
        text-align: right;
      }
    }

    .item-title {
      margin: 0;
      font-size: 26px;
    }

    .item-text {
      font-size: 18px;
    }

    .design-line-desktop {
      position: absolute;
      top: 163px;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed var(--main-text);
    }
  }
}

@include for-size(desktop-m) {
  .section.how-it-works {
    padding: 100px 0;

    .flex-container {
      gap: 45px;
    }

    .content-box {
      .title {
        margin: 0;
        font-size: 60px;

        .break1,
        .break2 {
          margin-left: 273px;
        }
      }

      .subtitle {
        position: relative;
        bottom: 90px;
        left: -8px;
        margin: 0;
      }
    }

    .img-box {
      height: 420px;
    }
  }

  .how-it-works-list {
    .item {
      &:nth-child(3) {
        .item-text {
          margin-bottom: 25px;
        }
      }
    }

    .item-title {
      font-size: 28px;
    }

    .item-text {
      font-size: 21px;
    }
  }
}

@include for-size(desktop-l) {
  .section.how-it-works {
    padding: 220px 0;

    .container1 {
      margin-bottom: 175px;
    }

    .flex-container {
      gap: 150px;
    }

    .content-box {
      top: 58px;
      left: 9px;

      .title {
        margin: 0 0 40px 0;
        font-size: 80px;

        .break1,
        .break2 {
          margin-left: 423px;
        }
      }

      .subtitle {
        position: relative;
        bottom: 154px;
        left: 4px;
        margin: 0;
        font-size: 20px;
      }
    }

    .img-box {
      min-width: 664px;
      height: 516px;
    }
  }

  .how-it-works-list {
    padding-left: 216px;

    .container-desktop {
      justify-content: flex-start;
    }

    .width-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 350px;
    }

    .item {
      &:not(:last-child) {
        margin-right: 287px;
      }
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: none;

        &::after {
          top: 61%;
          bottom: unset;
          left: 49%;
          font-size: 41px;
        }
      }
      &:nth-child(3) {
        .item-text {
          position: relative;
          right: 37px;
          margin-bottom: 30px;
          font-size: 24px;
        }

        &::after {
          top: 61%;
          left: 44%;
          font-size: 41px;
        }
      }
    }

    .item-title {
      font-size: 32px;
    }

    .item-text {
      position: relative;
      right: 10px;
      font-size: 24px;
    }

    .order-number {
      margin-bottom: 30px;
    }

    .design-line-desktop {
      top: 215px;
    }
  }
}
