.section.about-ceo {
  padding: 40px 0;

  .content-box {
    margin-bottom: 40px;

    .text-box {
      .title {
        margin-bottom: 26px;
        font-weight: 700;
        font-size: 38px;
        line-height: 100%;
        text-transform: uppercase;
        color: var(--black);
      }

      .subtitle {
        margin-bottom: 30px;
        font-size: 22px;
        line-height: 125%;
        text-transform: uppercase;
        text-align: right;
        color: var(--light-black);
      }

      .img-box-mobile {
        margin-bottom: 30px;
      }
    }

    .img-box {
      &.desktop {
        display: none;
      }
    }
  }
}

.about-ceo-benefits-list {
  .item {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .item-text {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    line-height: 120%;

    .icon {
      margin-right: 22px;
      max-width: 17px;
      max-height: 17px;
    }

    .bold {
      font-weight: 500;
      color: var(--black);
    }
  }
}

.about-ceo-quote-box {
  text-align: center;

  .quote {
    margin-bottom: 12px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: var(--black);
  }
  .author {
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    text-transform: uppercase;
  }
}

@include for-size(mobile-s) {
  .section.about-ceo {
    .content-box {
      .text-box {
        .title {
          font-size: 49px;
        }
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.about-ceo {
    .content-box {
      margin-bottom: 60px;

      .text-box {
        .title {
          font-size: 74px;
        }

        .subtitle {
          margin-bottom: 40px;
          font-size: 32px;
        }

        .img-box-mobile {
          margin-bottom: 50px;
        }
      }
    }
  }

  .about-ceo-benefits-list {
    .item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  .about-ceo-quote-box {
    margin-bottom: 50px;

    .quote {
      font-size: 24px;
    }
  }
}

@include for-size(tablet) {
  .section.about-ceo {
    padding: 40px 0 70px 0;

    .content-box {
      margin-bottom: 80px;

      .text-box {
        .img-box-mobile {
          margin: 0 0 70px auto;
          width: 510px;
        }
      }
    }
  }

  .about-ceo-quote-box {
    margin-bottom: 80px;
  }
}

@include for-size(desktop-s) {
  .section.about-ceo {
    padding: 40px 0 90px 0;

    .content-box {
      display: flex;
      align-items: flex-start;
      gap: 60px;

      .text-box {
        margin-top: 30px;
        flex-basis: calc((100% - 60px * 1) / 2);

        .title {
          font-size: 63px;
        }

        .img-box-mobile {
          display: none;
        }
      }

      .img-box {
        flex-basis: calc((100% - 60px * 1) / 2);
        &.desktop {
          display: block;
        }
      }
    }
  }

  .about-ceo-quote-box {
    .quote {
      margin: 0 auto 12px auto;
      max-width: 830px;
      font-size: 30px;
    }
    .author {
      font-weight: 500;
      font-size: 14px;
      line-height: 143%;
      text-transform: uppercase;
    }
  }
}

@include for-size(desktop-m) {
  .section.about-ceo {
    padding: 40px 0 100px 0;

    .content-box {
      margin-bottom: 120px;
    }

    .content-box {
      .text-box {
        .title {
          font-size: 76px;
        }
      }
    }
  }

  .about-ceo-quote-box {
    margin-bottom: 120px;

    .quote {
      max-width: 970px;
      font-size: 40px;
    }
  }
}

@include for-size(desktop-l) {
  .section.about-ceo {
    padding: 105px 0 100px 0;

    .content-box {
      gap: 121px;
      margin-bottom: 171px;

      .text-box {
        flex-basis: unset;
        margin-top: 64px;

        .title {
          margin-bottom: 35px;
          font-size: 121px;
        }

        .subtitle {
          margin: 0 37px 48px 0;
        }
      }

      .img-box {
        flex-basis: unset;
        max-width: 664px;
        height: 852px;
      }
    }
  }

  .about-ceo-benefits-list {
    margin-left: 140px;

    .item {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .item-text {
      .icon {
        margin-top: 3px;
      }
    }
  }

  .about-ceo-quote-box {
    margin-bottom: 163px;
    .quote {
      margin: 0 auto 27px;
    }
  }
}
