:root {
  --font-family: 'Inter', sans-serif;
  --main-text: #9599a6;
  --black: #000000;
  --light-black: #5e616b;
  --light-green: rgba(76, 239, 137, 0.3);
  --green: #4cef89;
  --white: #ffffff;
  --transition: all 400ms ease;
}
