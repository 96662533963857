.main-title {
  margin-bottom: 85px;
  font-weight: 700;
  font-size: 48px;
  line-height: 0.4;
  text-transform: uppercase;
  color: var(--black);
  transition: var(--transition);

  .copy {
    position: relative;
    top: -15px;
    font-size: 34px;
  }
}

@include for-size(mobile-s) {
  .main-title {
    font-size: 64px;

    .copy {
      font-size: 54px;
    }
  }
}

@include for-size(mobile-m) {
  .main-title {
    margin-bottom: 120px;
    font-size: 90px;

    .copy {
      font-size: 80px;
    }
  }
}

@include for-size(tablet) {
  .main-title {
    margin-bottom: 160px;
    font-size: 114px;

    .copy {
      top: -30px;
    }
  }
}

@include for-size(desktop-s) {
  .main-title {
    margin-bottom: 86px;
    font-size: 84px;

    .copy {
      top: -8px;
    }
  }
}

@include for-size(desktop-m) {
  .main-title {
    font-size: 94px;

    .copy {
      top: -15px;
    }
  }
}

@include for-size(desktop-l) {
  .main-title {
    margin-bottom: 74px;
    font-size: 170px;

    .copy {
      top: -58px;
      font-size: 100px;
    }
  }
}
