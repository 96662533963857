.section.safety {
  padding: 40px 0;

  .content-box {
    margin-bottom: 40px;

    .title {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 36px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--black);

      .break1 {
        margin-left: 60px;
      }
    }

    .subtitle {
      margin: 0 0 30px 50px;
      font-size: 16px;
      line-height: 125%;
      text-transform: uppercase;
      color: var(--light-black);

      .break1 {
        margin-left: 55px;
      }
    }
  }

  .safety-btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .safety-circle-btn {
    width: 186px;
    height: 186px;
    line-height: 120%;
  }
}

@include for-size(mobile-s) {
  .section.safety {
    .content-box {
      .title {
        font-size: 46px;

        .break1 {
          margin-left: 70px;
        }
      }

      .subtitle {
        font-size: 22px;
        .break1 {
          margin-left: 70px;
        }
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.safety {
    padding: 60px 0;

    .content-box {
      margin-bottom: 70px;

      .title {
        font-size: 66px;

        .break1 {
          margin-left: 100px;
        }
      }

      .subtitle {
        margin: 0 0 30px 150px;
        font-size: 27px;

        .break1 {
          margin-left: 85px;
        }
      }
    }

    .img-box {
      margin-left: auto;
      width: 470px;
    }

    .safety-circle-btn {
      margin-right: 200px;
    }
  }
}

@include for-size(tablet) {
  .section.safety {
    padding: 90px 0;

    .content-box {
      position: relative;
      margin-bottom: 90px;

      .title {
        font-size: 80px;

        .break1 {
          margin-left: 125px;
        }
      }

      .subtitle {
        margin: 0 0 0 250px;
      }
    }

    .safety-btn-box {
      position: absolute;
      left: 0;
      bottom: -45%;
    }

    .safety-circle-btn {
      margin-right: 0;
      width: 236px;
      height: 236px;
    }
  }
}

@include for-size(desktop-s) {
  .section.safety {
    padding: 110px 0;

    .safety-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 50px;
    }

    .content-box {
      flex-basis: calc((100% - 50px * 1) / 2);
      margin-bottom: 0;

      .title {
        font-size: 51px;

        .break1 {
          margin-left: 80px;
        }
      }

      .subtitle {
        font-size: 24px;
        margin: 0 0 40px 100px;

        .break1 {
          margin-left: 75px;
        }
      }
    }

    .img-box {
      margin: 55px 0 0 0;
      flex-basis: calc((100% - 50px * 1) / 2);
    }

    .safety-btn-box {
      position: static;
      justify-content: flex-start;
    }
  }
}

@include for-size(desktop-m) {
  .section.safety {
    padding: 130px 0;

    .safety-container {
      gap: 70px;
    }

    .content-box {
      flex-basis: calc((100% - 70px * 1) / 2);

      .title {
        margin-bottom: 40px;
        font-size: 67px;

        .break1 {
          margin-left: 105px;
        }
      }

      .subtitle {
        font-size: 28px;
        margin: 0 0 40px 150px;

        .break1 {
          margin-left: 85px;
        }
      }
    }

    .img-box {
      margin: 70px 0 0 0;
      flex-basis: calc((100% - 70px * 1) / 2);
      height: 608px;
    }

    .safety-btn-box {
      position: static;
      justify-content: flex-start;
    }

    .safety-circle-btn {
      margin-right: 0;
      width: 256px;
      height: 256px;
    }
  }
}

@include for-size(desktop-l) {
  .section.safety {
    padding: 186px 0 176px 0;

    .safety-container {
      justify-content: flex-end;
      gap: 160px;
    }

    .content-box {
      flex-basis: unset;

      .title {
        margin-bottom: 42px;
        font-size: 80px;

        .break1 {
          margin-left: 130px;
        }
      }

      .subtitle {
        font-size: 32px;
        margin: 0 0 40px 281px;

        .break1 {
          margin-left: 100px;
        }
      }
    }

    .img-box {
      margin: 89px 0 0 0;
      flex-basis: unset;
      height: 608px;
      width: 664px;
    }

    .safety-btn-box {
      position: static;
      justify-content: flex-start;
    }

    .safety-circle-btn {
      position: relative;
      bottom: 108px;
    }
  }
}
