.bg-text {
  background-repeat: no-repeat;
  background-position: 0 12px;
  background-size: 0;
  transition: var(--transition);
  transition-duration: 2s;
  background-image: linear-gradient(
    90deg,
    rgba(219, 252, 231, 1) 0%,
    rgba(219, 252, 231, 1) 50%,
    rgba(219, 252, 231, 1) 100%
  );

  &.is-show {
    background-size: 100%;
  }

  &.bg-text-ios {
    background-position: 0 12px;
  }
}
