.section.services {
  padding: 50px 0;

  .title {
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 57px;
    line-height: 100%;
    text-transform: uppercase;
    text-align: right;
    color: var(--black);
  }
}

.services-list {
  .item {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  .img-box {
    margin-bottom: 40px;
  }

  .content-box {
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }

  .order-box {
    .order {
      font-weight: 100;
      font-size: 28px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--main-text);
    }
    .number {
      font-weight: 700;
      font-size: 24px;
      color: var(--green);
    }
  }

  .text-box {
    .item-title {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 19px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--black);
    }

    .item-text {
      margin-bottom: 20px;
    }
  }
}

@include for-size(mobile-m) {
  .section.services {
    .title {
      font-size: 80px;
    }
  }

  .services-list {
    .content-box {
      gap: 50px;
    }

    .text-box {
      .item-title {
        margin-bottom: 20px;
        font-size: 32px;
      }

      .item-text {
        margin-bottom: 40px;
        font-size: 20px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.services {
    padding: 50px 0 0 0;

    .title {
      margin-bottom: 75px;
    }
  }

  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;

    .item {
      position: relative;
      flex-basis: calc((100% - 50px * 1) / 2);

      &:not(:last-child) {
        margin-bottom: 0;
      }

      &:nth-child(1) {
        bottom: 145px;
      }
      &:nth-child(3) {
        bottom: 145px;
      }
    }

    .content-box {
      gap: 15px;
    }

    .text-box {
      .item-title {
        font-size: 22px;
      }

      .item-text {
        font-size: 15px;
      }
    }
  }
}

@include for-size(desktop-s) {
  .section.services {
    padding: 110px 0 0 0;
  }

  .services-list {
    gap: 100px;

    .item {
      flex-basis: calc((100% - 100px * 1) / 2);
    }

    .content-box {
      gap: 30px;
    }

    .text-box {
      .item-title {
        font-size: 32px;
      }

      .item-text {
        font-size: 18px;
      }
    }
  }
}

@include for-size(desktop-m) {
  .services-list {
    gap: 150px;

    .item {
      flex-basis: calc((100% - 150px * 1) / 2);
    }

    .content-box {
      gap: 80px;
    }

    .text-box {
      .item-title {
        font-size: 32px;
      }

      .item-text {
        font-size: 20px;
      }
    }
  }
}

@include for-size(desktop-l) {
  .section.services {
    padding: 136px 0 0 0;

    .title {
      margin: 0 270px 221px 0;
    }
  }

  .services-list {
    gap: 303px;
    height: 1840px;

    .item {
      flex-basis: unset;

      &:nth-child(1) {
        bottom: 314px;

        .content-box {
          gap: 95px;
        }
      }

      &:nth-child(3) {
        bottom: 530px;
        left: 280px;
      }
    }

    .content-box {
      gap: 85px;
    }

    .img-box {
      margin-bottom: 57px;
    }

    .text-box {
      .item-title {
        font-size: 32px;
      }

      .item-text {
        margin-bottom: 37px;
        font-size: 20px;
        line-height: 120%;
      }
    }
  }
}
