.section.team {
  padding: 60px 0;

  .content-box {
    margin-bottom: 40px;

    .title {
      margin-bottom: 14px;
      font-weight: 700;
      font-size: 52px;
      line-height: 83%;
      text-transform: uppercase;
      color: var(--black);
    }

    .subtitle {
      margin: 0 0 50px 25px;
      font-size: 18px;
      line-height: 125%;
      text-transform: uppercase;
      color: var(--light-black);

      .break1 {
        margin-left: 70px;
      }
      .break2 {
        margin-left: 110px;
      }

      .bold {
        font-weight: 400;

        span {
          align-self: flex-start;
        }
      }
    }

    .text-block {
      .item {
        font-size: 16px;
        line-height: 120%;

        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .bold {
          font-weight: 500;
          color: var(--black);
        }
      }
    }
  }
}

@include for-size(mobile-s) {
  .section.team {
    .content-box {
      .title {
        font-size: 64px;
      }

      .subtitle {
        margin: 0 0 50px 100px;
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.team {
    padding: 80px 0;

    .content-box {
      margin-bottom: 80px;

      .title {
        margin-bottom: 24px;
        font-size: 82px;
      }

      .subtitle {
        margin: 0 0 50px 203px;
      }

      .text-block {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .item {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    .img-box {
      margin-left: auto;
      width: 424px;
    }
  }
}

@include for-size(tablet) {
  .section.team {
    position: relative;
    padding: 90px 0 240px 0;

    .content-box {
      margin-bottom: 0;

      .title {
        font-size: 92px;
      }

      .subtitle {
        margin: 0 0 50px 280px;
        font-size: 23px;

        .break1 {
          margin-left: 87px;
        }
        .break2 {
          margin-left: 140px;
        }
      }

      .text-block {
        flex-direction: column;

        .item {
          &:not(:last-child) {
            margin-bottom: 30px;
          }
        }
      }
    }

    .img-box {
      position: absolute;
      top: 45%;
      right: 0;
    }
  }
}

@include for-size(desktop-s) {
  .section.team {
    padding: 120px 0 240px 0;

    .content-box {
      .title {
        font-size: 120px;
      }

      .subtitle {
        font-size: 28px;

        .break1 {
          margin-left: 105px;
        }
        .break2 {
          margin-left: 170px;
        }
      }

      .text-block {
        .item {
          font-size: 20px;
        }
      }
    }

    .img-box {
      top: 48%;
      right: 3%;
      width: 524px;
    }
  }
}

@include for-size(desktop-m) {
  .section.team {
    padding: 120px 0 140px 0;

    .team-container {
      display: flex;
      justify-content: space-between;
    }

    .content-box {
      .title {
        font-size: 98px;
      }

      .subtitle {
        margin: 0 0 50px 165px;
      }
    }

    .img-box {
      position: static;
      margin-left: 0;
      width: 566px;
      height: 796px;
    }
  }
}

@include for-size(desktop-l) {
  .section.team {
    padding: 260px 0 140px 0;

    .team-container {
      justify-content: flex-start;
      gap: 205px;
    }

    .content-box {
      .title {
        position: relative;
        top: -6px;
        margin-bottom: 40px;
        font-size: 120px;
      }

      .subtitle {
        margin: 0 0 108px 275px;
        font-size: 32px;

        .break1 {
          margin-left: 125px;
        }
        .break2 {
          margin-left: 200px;
        }
      }

      .text-block {
        flex-direction: row;
        justify-content: flex-start;
        gap: 94px;
      }
    }

    .img-box {
      position: static;
      margin-left: 0;
      width: 664px;
      height: 796px;
    }
  }
}
