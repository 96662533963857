.section.benefits {
  padding: 40px 0;

  .content-box {
    margin-bottom: 50px;

    .title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 48px;
      line-height: 0.8;
      text-transform: uppercase;
      color: var(--black);
    }

    .subtitle {
      position: relative;
      margin: 0 0 40px 10px;
      font-size: 18px;
      line-height: 125%;
      text-transform: uppercase;
      color: var(--light-black);

      .break1 {
        margin-left: 20px;
      }
      .break4 {
        margin-left: 160px;
      }
    }

    .text {
      font-size: 14px;
      line-height: 1.2;

      .bold {
        font-weight: 500;
        color: var(--black);
      }
    }
  }

  .img-box {
    margin-bottom: 50px;
  }
}

.benefits-list {
  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .number-box {
    margin-bottom: 12px;
    border-bottom: 1px solid var(--black);
    overflow: hidden;

    .number {
      position: relative;
      bottom: -25px;
      font-weight: 700;
      font-size: 100px;
      line-height: 1;
      text-transform: uppercase;
      color: #000;

      .plus {
        position: relative;
        bottom: 40px;
        font-size: 60px;
      }
    }
  }

  .item-text {
    text-align: right;
    font-size: 24px;
    line-height: 1.2;
    text-transform: uppercase;
  }
}

@include for-size(mobile-s) {
  .section.benefits {
    .content-box {
      .title {
        font-size: 56px;
      }

      .subtitle {
        margin: 0 0 40px 70px;
      }

      .text {
        font-size: 18px;
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.benefits {
    .content-box {
      margin-bottom: 60px;

      .title {
        margin-bottom: 40px;
        font-size: 86px;
      }

      .subtitle {
        margin: 0 0 60px 70px;
        font-size: 32px;

        .break1 {
          margin-left: 36px;
        }
        .break4 {
          margin-left: 288px;
        }
      }

      .text {
        font-size: 20px;
      }
    }

    .img-box {
      margin: 0 0 60px auto;
      max-width: 526px;
    }
  }

  .benefits-list {
    .item-text {
      margin-left: auto;
      max-width: 310px;
    }
  }
}

@include for-size(tablet) {
  .section.benefits {
    padding: 60px 0;

    .content-box {
      .title {
        margin-bottom: 60px;
        font-size: 107px;
      }

      .subtitle {
        margin: 0 0 60px 150px;
      }
    }

    .img-box {
      margin: 0 0 100px auto;
    }
  }

  .benefits-list {
    .item {
      .number {
        margin-left: 70px;
      }
    }
  }
}

@include for-size(desktop-s) {
  .section.benefits {
    .flex-container {
      display: flex;
      align-items: center;
      margin-bottom: 100px;
    }

    .content-box {
      margin: 0;
      width: 60%;

      .title {
        font-size: 76px;
      }

      .subtitle {
        font-size: 21px;

        .break1 {
          margin-left: 24px;
        }
        .break4 {
          margin-left: 192px;
        }
      }
    }

    .img-box {
      width: 40%;
      margin: 0;
    }
  }

  .benefits-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 50px;

    .item {
      flex-basis: calc((100% - 50px * 2) / 3);

      &:not(:last-child) {
        margin: 0;
      }
      .number {
        margin-left: 0;
      }
    }
  }
}

@include for-size(desktop-m) {
  .section.benefits {
    padding: 80px 0;

    .flex-container {
      align-items: flex-start;
      margin-bottom: 130px;
    }

    .content-box {
      .title {
        font-size: 86px;
      }

      .subtitle {
        font-size: 28px;

        .break1 {
          margin-left: 31px;
        }
        .break4 {
          margin-left: 252px;
        }
      }
    }

    .img-box {
      position: relative;
      top: 40px;
      width: 100;
      min-width: 526px;
    }
  }
}

@include for-size(desktop-l) {
  .section.benefits {
    padding: 21px 0 80px 0;

    .flex-container {
      margin-bottom: 170px;
      gap: 125px;
    }

    .content-box {
      .title {
        margin: 0 0 52px 131px;
        font-size: 120px;
      }

      .subtitle {
        margin: 0 0 67px 417px;
        font-size: 32px;

        .break4 {
          margin-left: 284px;
        }
      }

      .text {
        margin-left: 137px;
      }
    }

    .img-box {
      top: 47px;
    }
  }

  .benefits-list {
    gap: 160px;

    .item {
      flex-basis: unset;
      width: 390px;
    }
  }
}
