.section.hero {
  padding: 20px 0;

  .content-box {
    .reverse-content {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .img-box {
    position: relative;
    margin: 0 auto 30px auto;
    width: 100%;
    max-width: 664px;
  }

  .content-bottom-box-desktop {
    display: none;
  }

  .content-box-mobile {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 auto;
    width: 100%;
    max-width: 664px;
  }
}

.hero-subtitle {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 1.1;
  text-transform: uppercase;
  color: var(--light-black);

  .break1 {
    margin-left: 20px;
  }
  .break2 {
    margin-left: 80px;
  }
}

.hero-circle-btn {
  position: absolute;
  top: -45px;
  left: -10px;

  &.desktop {
    display: none;
  }
}
.hero-text {
  position: relative;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  text-transform: uppercase;

  .star {
    position: absolute;
    top: 5px;
    left: 0;
    font-weight: 500;
    font-size: 32px;
    line-height: 0.6;
    text-transform: uppercase;
    color: var(--black);
  }

  .break1 {
    margin-left: 25px;
  }
}
.hero-arrow-btn {
  padding: 5px;
  margin: 0;
  background-color: transparent;
  border: none;

  .icon {
    width: 69px;
    height: 55px;
  }
}

@include for-size(mobile-m) {
  .section.hero {
    padding: 30px 0;
  }

  .hero-subtitle {
    margin: 0 0 70px 80px;
    font-size: 24px;

    .break1 {
      margin-left: 25px;
    }
    .break2 {
      margin-left: 105px;
    }
  }

  .hero-circle-btn {
    top: -50px;
    left: -15px;
  }
}

@include for-size(tablet) {
  .hero-subtitle {
    margin: 0 0 85px 80px;
  }

  .hero-circle-btn {
    top: -60px;
    left: -40px;
  }
}

@include for-size(desktop-s) {
  .section.hero {
    .content-box,
    .img-box {
      width: 50%;
    }

    .content-box {
      margin-top: 108px;
      .reverse-content {
        flex-direction: column;
      }
    }

    .img-box {
      max-width: unset;
      margin: 0;
    }

    .content-bottom-box-desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .content-box-mobile {
      display: none;
    }
  }

  .hero-container {
    display: flex;
    gap: 20px;
  }

  .hero-subtitle {
    margin: 0 0 0 80px;
  }

  .hero-circle-btn {
    position: relative;
    top: -28px;
    left: 120px;
    z-index: 2;

    &.mobile {
      display: none;
    }
    &.desktop {
      display: block;
    }
  }
}

@include for-size(desktop-m) {
  .section.hero {
    padding: 0 0 30px 0;
  }

  .content-bottom-box-desktop {
    margin-top: 70px;
  }
}

@include for-size(desktop-l) {
  .section.hero {
    .content-box {
      margin-top: 176px;
      width: 55%;
    }

    .img-box {
      top: -5px;
      width: 664px;
      height: 860px;
    }

    .content-bottom-box-desktop {
      margin-top: 22px;
    }
  }

  .hero-container {
    gap: 70px;
  }

  .hero-subtitle {
    margin: 0 0 0 275px;
    font-size: 32px;

    .break1 {
      margin-left: 45px;
    }

    .break2 {
      margin-left: 145px;
    }
  }

  .hero-circle-btn {
    top: -38px;
    left: 364px;
  }

  .hero-text {
    .break1 {
      margin-left: 28px;
    }
  }

  .hero-arrow-btn {
    margin-right: 40px;

    .icon {
      width: 75px;
      height: 70px;
    }
  }
}
