.section.success {
  padding: 100px 0;

  .btn-box {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .success-circle-btn {
    width: 156px;
    height: 156px;
  }

  .download-btn {
    line-height: 1.2;
  }
}
.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-logo {
  &.success-page {
    margin-bottom: 177px;

    .copy {
      position: relative;
      bottom: 5px;
      right: 3px;
      font-size: 23px;
    }
  }
}
.success-main-title {
  margin-bottom: 46px;
  font-weight: 700;
  font-size: 60px;
  line-height: 38%;
  text-transform: uppercase;
  color: var(--black);
}
.success-subtitle {
  margin-bottom: 50px;
  font-size: 22px;
  line-height: 112%;
  text-transform: uppercase;
  color: var(--light-black);
}
.footer-success {
  padding: 40px 0;
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .copy-box {
      display: flex;
      align-items: center;
      gap: 30px;

      .copy,
      .policy {
        font-weight: 500;
        font-size: 14px;
        line-height: 143%;
        text-transform: uppercase;
        color: var(--main-text);
      }
    }
  }
}

@include for-size(mobile-m) {
  .section.success {
    .btn-box {
      flex-direction: row;
    }
  }

  .success-main-title {
    margin-bottom: 60px;
    font-size: 100px;
  }

  .success-subtitle {
    margin: 0 0 50px 150px;
    font-size: 32px;
  }
}

@include for-size(tablet) {
  .section.success {
    padding: 124px 0;

    .success-circle-btn {
      width: 256px;
      height: 256px;
    }
  }

  .success-main-title {
    margin-bottom: 70px;
    font-size: 130px;
  }

  .success-subtitle {
    margin: 0 0 50px 320px;
    font-size: 32px;
  }

  .footer-success {
    padding: 96px 0;
  }
}

@include for-size(desktop-s) {
  .section.success {
    padding: 123px 0 122px 0;

    .btn-box {
      gap: 60px;
    }
  }

  .success-main-title {
    margin-bottom: 86px;
    font-size: 170px;
  }

  .success-subtitle {
    margin: 0 0 75px 320px;
  }

  .footer-success {
    .box {
      .copy-box {
        gap: 166px;
      }

      .ra-box {
        position: relative;
        top: -3px;
        right: 83px;
      }
    }
  }
}
