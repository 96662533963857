.footer {
  padding: 60px 0;

  .box {
    &:nth-child(1) {
      margin-bottom: 70px;
      .contacts-list {
        .item {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      .title {
        margin-bottom: 14px;
        font-weight: 700;
        font-size: 24px;
        line-height: 125%;
        text-transform: uppercase;
        color: var(--black);
      }

      .text,
      .link-contact,
      .link-social {
        font-size: 24px;
        line-height: 125%;
        text-transform: uppercase;
        color: var(--main-text);
      }

      .link-social {
        text-decoration: underline;
      }
    }

    &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .copy-box {
        display: flex;
        align-items: center;
        gap: 30px;

        .copy,
        .policy {
          font-weight: 500;
          font-size: 14px;
          line-height: 143%;
          text-transform: uppercase;
          color: var(--main-text);
        }
      }
    }
  }

  .footer-logo {
    display: block;
    margin-bottom: 80px;
  }
}

@include for-size(desktop-s) {
  .footer {
    padding: 100px 0 50px 0;

    .box {
      &:nth-child(1) {
        display: flex;
        align-items: flex-start;
        gap: 136px;
      }
    }

    .footer-logo {
      margin: 20px 0 0 0;
    }
  }
}

@include for-size(desktop-l) {
  .footer {
    position: relative;
    padding: 193px 0 50px 0;

    .box {
      &:nth-child(1) {
        .contacts-list {
          .item {
            &:not(:last-child) {
              margin-bottom: 47px;
            }
          }
        }
      }

      &:nth-child(2) {
        position: absolute;
        bottom: 19%;
        width: 1553px;

        .copy-box {
          gap: 166px;
        }
      }
    }

    .footer-logo {
      position: relative;
      right: 7px;
      margin: 27px 0 0 0;
    }
  }
}
