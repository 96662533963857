.section.client-problems {
  padding: 40px 0;
  .img-box {
    width: 225px;
  }
}

.client-problems-container {
  display: flex;
  flex-direction: column-reverse;
}

.client-problems__list {
  margin-bottom: 40px;

  .item {
    padding: 18px 0;
    border-bottom: 1px solid var(--black);

    .text {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 1.2;
      text-transform: uppercase;

      .bold {
        font-weight: 700;
        color: var(--black);
      }
    }

    &:nth-child(1) {
      .text {
        .break1 {
          margin-left: 10px;
        }
        .break2 {
          margin-left: 20px;
        }
        .break3 {
          margin-left: 90px;
        }
        .break4 {
          margin-left: 120px;
        }
      }
    }
    &:nth-child(2) {
      .text {
        .break1 {
          margin-left: 15px;
          margin-right: 15px;
        }
        .break2 {
          margin-left: 108px;
        }
        .break3 {
          margin-left: 73px;
        }
        .break4 {
          margin-left: 120px;
        }
      }
    }
    &:nth-child(3) {
      .text {
        .break1 {
          margin-left: 35px;
        }
        .break2 {
          margin-left: 70px;
        }
        .break3 {
          margin-left: 25px;
        }
        .break4 {
          margin-left: 180px;
        }
      }
    }
  }

  .order {
    font-weight: 100;
    color: var(--black);

    .number {
      font-weight: 700;
      font-size: 16px;
      line-height: 1;
      text-transform: uppercase;
      color: var(--green);
    }
  }
}

@include for-size(mobile-s) {
  .section.client-problems {
    .img-box {
      width: 310px;
    }
  }

  .client-problems__list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }
}

@include for-size(mobile-m) {
  .section.client-problems {
    .img-box {
      width: 405px;
    }
  }

  .client-problems__list {
    margin-bottom: 80px;

    .item {
      padding: 28px 0;

      .text {
        margin-bottom: 0;
        font-size: 24px;
      }

      &:nth-child(2) {
        .text {
          margin-left: 50px;
        }
      }
      &:nth-child(3) {
        .text {
          margin-left: 100px;
        }
      }
    }

    .order {
      font-size: 28px;
      .number {
        font-size: 24px;
      }
    }
  }
}

@include for-size(tablet) {
  .section.client-problems {
    .img-box {
      width: 460px;
    }
  }

  .client-problems__list {
    margin-bottom: 40px;

    .item {
      &:nth-child(2) {
        .text {
          margin-left: 100px;
        }
      }
      &:nth-child(3) {
        .text {
          margin-left: 200px;
        }
      }
    }
  }
}

@include for-size(desktop-s) {
  .section.client-problems {
    padding: 80px 0;

    .img-box {
      min-width: 366px;
      height: 316px;
    }
  }

  .client-problems-container {
    flex-direction: row;
    align-items: center;
    gap: 50px;
  }

  .client-problems__list {
    margin-bottom: 0;
    flex-grow: 1;

    .item {
      &:nth-child(2) {
        .text {
          margin-left: 0;
        }
      }
      &:nth-child(3) {
        .text {
          margin-left: 0;
        }
      }
    }
  }
}

@include for-size(desktop-m) {
  .section.client-problems {
    padding: 120px 0;

    .img-box {
      height: 416px;
    }
  }

  .client-problems-container {
    align-items: flex-end;
  }

  .client-problems__list {
    .item {
      &:nth-child(1) {
        .text {
          .break1 {
            margin-left: 70px;
          }
          .break2 {
            margin-left: 40px;
          }
          .break3 {
            margin-left: 290px;
          }
          .break4 {
            margin-left: 345px;
          }
        }
      }
      &:nth-child(2) {
        .text {
          margin-left: 100px;
          .break1 {
            margin-left: 40px;
            margin-right: 40px;
          }
          .break2 {
            margin-left: 162px;
          }
          .break3 {
            margin-left: 120px;
          }
          .break4 {
            margin-left: 215px;
          }
        }
      }
      &:nth-child(3) {
        .text {
          margin-left: 200px;
          .break1 {
            margin-left: 90px;
          }
          .break2 {
            margin-left: 110px;
          }
          .break3 {
            margin-left: 30px;
          }
          .break4 {
            margin-left: 270px;
          }
        }
      }
    }
  }
}

@include for-size(desktop-l) {
  .section.client-problems {
    padding: 176px 0;

    .img-box {
      min-width: 466px;
      height: 416px;
    }
  }

  .client-problems-container {
    gap: 222px;
  }

  .client-problems__list {
    .item {
      &:nth-child(1) {
        padding: 32px 0 28px 0;

        .text {
          .break1 {
            margin-left: 70px;
          }
          .break2 {
            margin-left: 57px;
          }
          .break3 {
            margin-left: 297px;
          }
          .break4 {
            margin-left: 350px;
          }
        }
      }
      &:nth-child(2) {
        padding: 30px 0 26px 0;

        .text {
          margin-left: 138px;
          .break1 {
            margin-left: 50px;
            margin-right: 45px;
          }
          .break2 {
            margin-left: 162px;
          }
          .break3 {
            margin-left: 115px;
          }
          .break4 {
            margin-left: 210px;
          }
        }
      }
      &:nth-child(3) {
        padding: 29px 0 25px 0;

        .text {
          margin-left: 275px;
          .break1 {
            margin-left: 90px;
          }
          .break2 {
            margin-left: 110px;
          }
          .break3 {
            margin-left: 35px;
          }
          .break4 {
            margin-left: 277px;
          }
        }
      }
    }
  }
}
